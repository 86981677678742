@charset "UTF-8";
@charset "utf-8";

/* --------------------------------------------------
// bge_style - BurgerEditor
-------------------------------------------------- */
:root {
  --bgb-color_primary: #ec6c00;
  --bgb-color_secondary: #032739;
  --bgb-color_secondary--light: #626b70;
  --bgb-color_tertiary: #c7a956;
  --bgb-color_white: #fff;
  --bgb-color_gray: #f7f7f8;
  --bgb-color_black: #032739;
  --bgb-color_link: #3e90b2;
  --bgb-color_table_border: #d0d2d4;
  --bgb-block_mt: 1.5em;
  --bgb-font_en: 'Inter', sans-serif;
}
%btn_effect--left {
  content: '';
  position: absolute;
  top: 0;
  right: 200%;
  width: 100%;
  height: 100%;
  transition: right 0.3s 0s ease;
  z-index: 1;
}
.c-page_main {
  > [data-bgb] {
    margin-top: var(--bgb-block_mt);
  }
  [data-bgb]:first-of-type {
    margin-top: 0;
  }
}
[data-bgb] {
  h2 {
    position: relative;
    margin-top: var(--bgb-block_mt);
    padding-right: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    border-bottom: solid 1px var(--bgb-color_secondary);
    font-size: clamp(2.5rem, 3vw, 3.6rem);
    font-weight: 600;
    line-height: 1.4;
    color: var(--bgb-color_secondary);
    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 5px;
      height: 10px;
      background-color: var(--bgb-color_secondary);
    }
  }
  h3 {
    position: relative;
    margin-top: calc(var(--bgb-block_mt) * 1.5);
    padding-left: 25px;
    font-size: clamp(2.2rem, 2.5vw, 3.0rem);
    font-weight: 600;
    line-height: 1.4;
    color: var(--bgb-color_secondary);
    &::before {
      content: '';
      position: absolute;
      top: 0.7em;
      left: 0;
      width: 15px;
      height: 1px;
      background-color: var(--bgb-color_secondary);
      font-size: clamp(2.2rem, 2.5vw, 3.0rem);
    }
    .bgb-title + & {
    margin-top: var(--bgb-block_mt);
    }
  }
  h4 {
    margin-top: calc(var(--bgb-block_mt) * 1.5);
    font-size: clamp(1.8rem, 2.2vw, 2.4rem);
    font-weight: 500;
    line-height: 1.4;
    color: var(--bgb-color_secondary);
  }
  h5 {
    margin-top: var(--bgb-block_mt);
    font-size: clamp(1.6rem, 2.2vw, 1.8rem);
    font-weight: 500;
    line-height: 1.4;
    color: var(--bgb-color_secondary);
  }
  h6 {
    margin-top: var(--bgb-block_mt);
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.4;
    color: var(--bgb-color_secondary);
  }
  p {
    font-size: clamp(1.4rem, 1.5vw, 1.5rem);
    line-height: 1.8;
    letter-spacing: 0.08em;
    color: var(--bgb-color_secondary--light);
    &:not(:first-child) {
      margin-top: var(--bgb-block_mt);
    }
  }
  ul {
    margin-top: var(--bgb-block_mt);
    padding-left: 20px;
    color: var(--bgb-color_secondary--light);
    :where(li ul) {
      margin-top: 0;
    }
    li {
      font-size: clamp(1.4rem, 1.5vw, 1.5rem);
    }
    > li {
      position: relative;
      padding-left: 20px;
      &::before {
        content: '';
        position: absolute;
        top: 0.9em;
        left: 0;
        width: 5px;
        height: 1px;
        background-color: var(--bgb-color_secondary--light);
        font-size: clamp(1.4rem, 1.5vw, 1.5rem);
      }
      :where(ul,ol) {
        margin-top: 0;
        padding-left: 0;
        > li {
          position: relative;
          padding-left: 18px;
          &::before {
            content: '';
            position: absolute;
            top: 11px;
            left: 0;
            width: 6px;
            height: 6px;
            border-radius: 10px;
            background-color: var(--bgb-color_secondary--light);
          }
        }
      }
    }
  }
  ol {
    counter-reset: list_number 0;
    margin-top: var(--bgb-block_mt);
    color: var(--bgb-color_secondary--light);
    :where(li ul) {
      margin-top: 0;
    }
    li {
      font-size: clamp(1.4rem, 1.5vw, 1.5rem);
    }
    > li {
      counter-increment: list_number 1;
      position: relative;
      padding-left: 20px;
      &::before {
        content: counter(list_number,decimal-leading-zero) ".";
        font-family: var(--bgb-font_en);
        font-weight: 200;
        letter-spacing: 0.08em;
      }
      :where(ul,ol) {
        margin-top: 0;
      }
    }
  }
  strong {
    font-weight: bold;
  }
  b {
    font-weight: bold;
  }
  u {
    text-decoration: underline;
  }
  a {
    font-weight: 600;
    text-decoration: underline;
    &:where(:hover,:focus) {
      color: var(--bgb-color_link);
    }
  }
  sup {
    font-size: 60%;
    vertical-align: super;
  }
  sub {
    font-size: 60%;
    vertical-align: sub;
  }
  s {
    text-decoration: line-through;
  }
  blockquote {
    position: relative;
    margin-top: calc(var(--bgb-block_mt) * 2);
    margin-bottom: calc(var(--bgb-block_mt) * 2);
    padding: 20px 20px 20px 55px;
    background-color: var(--bgb-color_gray);
    @media screen and (max-width: 768px) {
      padding: 15px 15px 15px 50px;
    }
    &::before {
      content: '';
      position: absolute;
      top: 20px;
      left: 20px;
      display: block;
      width: 25px;
      height: 19px;
      background: url(../img/icon_blockquote.svg) no-repeat center center;
      background-size: 100%;
      @media screen and (max-width: 768px) {
        width: 18px;
        height: 14px;
      }
    }
    > *:first-child {
      margin-top: 0;
    }
  }
  table {
    width: 100%;
    color: var(--bgb-color_black);
    tr {
      border-bottom: solid 1px var(--bgb-color_table_border);
      &:first-child {
        border-top: solid 1px var(--bgb-color_table_border);
      }
    }
    th {
      padding: 10px;
      background-color: var(--bgb-color_gray);
    }
    td {
      padding: 10px;
    }
  }
  .bgt-grid {
    @media screen and (max-width: 768px) {
      margin-bottom: 20px;
    }
  }
}
[data-bgt="button"] {
  display: block !important;
  text-align: center;
  .bgt-btn {
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: clamp(280px, 50%, 500px);
    min-height: 60px;
    margin-right: auto;
    margin-left: auto;
    padding: 15px 20px;
    background-color: var(--bgb-color_white);
    border: solid 3px var(--bgb-color_secondary--light);
    font-size: clamp(1.3rem, 1.5vw, 1.5rem);
    font-weight: 500;
    line-height: 1.4;
    letter-spacing: 0.1em;
    text-decoration: none;
    transition: color 0.3s 0.1s ease;
    color: var(--bgb-color_secondary--light);
    &::before {
      background-color: var(--bgb-color_secondary--light);
      @extend %btn_effect--left;
    }
    &:where(:hover,:focus) {
      color: var(--bgb-color_white);
      &::before {
        right: 0;
      }
    }
  }
  .bgt-btn__text {
    position: relative;
    z-index: 10;
  }
  [data-bgt-button-kind='em'] .bgt-btn {
    font-weight: 600;
  }
  [data-bgt-button-kind='back'] .bgt-btn {
    padding-left: 60px;
    .bgt-btn__text {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      z-index: 15;
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: -40px;
        width: 25px;
        height: 1px;
        background-color: var(--bgb-color_secondary--light);
        transform: translateY(-50%);
      }
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: -38px;
        width: 19px;
        height: 19px;
        border-bottom: solid 1px var(--bgb-color_secondary--light);
        border-left: solid 1px var(--bgb-color_secondary--light);
        transform: translateY(-50%) rotate(45deg);
      }
    }
    &:hover,
    &:focus {
      .bgt-btn__text {
        &::before {
          background-color: var(--bgb-color_white);
        }
        &::after {
          border-color: var(--bgb-color_white);
        }
      }
    }
  }
}
[data-bgt="download-file"] {
  display: block !important;
  text-align: center;
  .bgt-download-file__link {
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: clamp(280px, 50%, 500px);
    min-height: 60px;
    margin-right: auto;
    margin-left: auto;
    padding: 15px 20px;
    background-color: var(--bgb-color_white);
    border: solid 3px var(--bgb-color_secondary--light);
    font-size: clamp(1.3rem, 1.5vw, 1.5rem);
    font-weight: 500;
    line-height: 1.4;
    letter-spacing: 0.1em;
    text-decoration: none;
    transition: color 0.3s 0.1s ease;
    color: var(--bgb-color_secondary--light);
    &::before {
      background-color: var(--bgb-color_secondary--light);
      @extend %btn_effect--left;
    }
    i,
    .bgt-link__name,
    .bgt-link__size {
      z-index: 5;
    }
    .bgt-link__name {
      margin-right: 5px;
      margin-left: 5px;
    }
    .bgt-link__size {
      font-size: 70%;
      letter-spacing: 0.05em;
    }
    &:where(:hover,:focus) {
      color: var(--bgb-color_white);
      &::before {
        right: 0;
      }
      i,
      .bgt-link__name,
      .bgt-link__size {
        color: var(--bgb-color_white);
      }
    }
  }
}
.bgb-wysiwyg2 {
  padding: 20px;
  .bgt-grid {
    @media screen and (max-width: 768px) {
      padding-top: 0;
    }
  }
}
.bgt-box__caption {
  margin-top: 10px;
  font-size: clamp(1.3rem, 1.5vw, 1.5rem);
}
[data-bgt="gallery"] {
  .bgt-gallery-ctrl__prev,
  .bgt-gallery-ctrl__next {
    display: block;
    width: 31px;
    height: 31px;
    background-color: rgba($color: #fff, $alpha: 0.7);
    border-radius: 31px;
    font-size: 0;
    color: transparent;
  }
  .bgt-gallery-ctrl__prev {
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 13px;
      height: 13px;
      border-left: solid 1px var(--bgb-color_secondary--light);
      border-bottom: solid 1px var(--bgb-color_secondary--light);
      transform: translateY(-50%) translateX(-50%) rotate(45deg);
      @media screen and (max-width: 768px) {
        bottom: 24px;
      }
    }
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: 15px;
      height: 1px;
      background-color: var(--bgb-color_secondary--light);
      transform: translateY(-50%) translateX(-50%);
    }
  }
  .bgt-gallery-ctrl__next {
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 13px;
      height: 13px;
      border-top: solid 1px var(--bgb-color_secondary--light);
      border-right: solid 1px var(--bgb-color_secondary--light);
      transform: translateY(-50%) translateX(-50%) rotate(45deg);
      @media screen and (max-width: 768px) {
        bottom: 24px;
      }
    }
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: 15px;
      height: 1px;
      background-color: var(--bgb-color_secondary--light);
      transform: translateY(-50%) translateX(-50%);
    }
  }
  [data-gallery-marker="thumbs"] + .bgt-gallery-marker {
    li {
      width: clamp(60px, 20%, 200px);
      height: clamp(60px, 100%, 100px);
      background-color: var(--bgb-color_secondary--light);
      aspect-ratio: 2 / 1;
      &.current {
        &::before {
          content: '';
          top: 0;
          height: 0;
          display: block;
          width: 100%;
          height: 100%;
          background-color: transparent;
          border: solid 3px var(--bgb-color_primary);
          pointer-events: none;
        }
      }
    }
  }
  [data-gallery-marker="dot"] + .bgt-gallery-marker {
    margin-top: 20px;
    li {
      width: 12px;
      height: 12px;
      margin-right: 5px;
      margin-left: 5px;
      background-image: none !important;
      background-color: var(--bgb-color_secondary--light);
      border-radius: 12px;
      &.current {
        background-color: var(--bgb-color_primary);
      }
      &::before {
        content: '';
        display: none;
      }
    }
  }
}

/* リノベコラム ビフォー・アフター */
/* ---------------------------------------- */
$image-text_number: "";
@for $image-text_number from 1 through 5 {
  .bgb-image-text#{$image-text_number},
  .bgb-image-link-text#{$image-text_number} {
    [data-bge="ckeditor"] {
      margin-top: 10px;
    }
  }
}
.bgb-image2,
.bgb-trimmed-image2,
.bgb-trimmed-image-link2,
.bgb-image-text2,
.bgb-image-link-text2 {
  &.before_after {
    position: relative;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 35em) {
      display: block;
    }
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: clamp(22px, 3vw, 30px);
      height: clamp(22px, 3vw, 30px);
      border-top: solid 1px var(--bgb-color_secondary--light);
      border-right: solid 1px var(--bgb-color_secondary--light);
      transform: translateY(-50%) translateX(-50%) rotate(45deg);
      @media screen and (max-width: 35em) {
        transform: translateY(0) translateX(-50%) rotate(135deg);
      }
    }
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: clamp(19px, 4.5vw, 42px);
      height: 1px;
      background-color: var(--bgb-color_secondary--light);
      transform: translateY(-50%) translateX(-50%);
      @media screen and (max-width: 35em) {
        width: 1px;
        height: 25px;
        transform: translateY(0) translateX(-50%);
      }
    }
    >.bgt-grid {
      position: relative;
      float: none;
      width: clamp(150px, 45%, 460px);
      @media screen and (max-width: 35em) {
        width: 100%;
      }
      &::before {
        font-family: var(--bgb-font_en);
        font-size: clamp(1.3rem, 1.5vw, 1.5rem);
        font-weight: 400;
        letter-spacing: 0.08em;
        text-transform: capitalize;
        color: var(--bgb-color_table_border);
      }
    }
    .bgt-grid--first {
      @media screen and (max-width: 35em) {
        padding-bottom: 30px;
      }
      &::before {
        content: 'Before';
      }
    }
    .bgt-grid--last {
      @media screen and (max-width: 35em) {
        padding-top: 30px;
      }
      &::before {
        content: 'After';
      }
    }
  }
}

/* はじめてのリノベーション */
/* ---------------------------------------- */
.p-about__renovation {
  &--list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-left: 0 !important;
  }
  .-list__item {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 48%;
    padding: 0 40px;
    margin-bottom: 60px;
    min-height: clamp(280px, 45vw, 480px);
    @media screen and (max-width: 768px) {
      justify-content: center;
      width: clamp(280px, 90vw, 480px);
      min-height: clamp(280px, 90vw, 480px);
      margin-right: auto;
      margin-bottom: 30px;
      margin-left: auto;
    }
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: clamp(280px, 100%, 480px);
      min-height: 100%;
      background-color: var(--bgb-color_gray);
      border-radius: 1000px;
      transform: translateY(-50%) translateX(-50%);
      z-index: -1;
    }
    &--head {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-top: 0;
      text-align: center;
      .-number {
        font-family: var(--bgb-font_en);
        font-size: clamp(4.0rem, 5vw, 6.0rem);
        font-weight: 200;
        font-style: italic;
      }
      .-title {
        font-size: clamp(2rem, 3vw, 2.3rem);
        letter-spacing: 0.1em;
      }
    }
    &--img {
      margin-top: 20px;
      margin-right: auto;
      margin-bottom: -40px;
      margin-left: auto;
      @media screen and (max-width: 768px) {
        margin-bottom: 0;
      }
      &01 {
        width: clamp(115px, 17vw, 230px);
      }
      &02 {
        width: clamp(145px, 21vw, 291px);
      }
      &03 {
        width: clamp(132px, 19vw, 264px);
        @media screen and (min-width: 768px) {
          margin-top: 40px;
        }
      }
      &04 {
        width: clamp(181px, 25vw, 363px);
        @media screen and (min-width: 768px) {
          margin-top: 30px;
        }
      }
    }
  }
}

/* リノベーションのながれ */
/* ---------------------------------------- */
.p-about__flow {
  counter-reset: about__flow_number 0;
  &--block {
    counter-increment: about__flow_number 1;
    position: relative;
    &:not(:last-child) {
      padding-bottom: 100px;
      &::before {
        content: '';
        position: absolute;
        bottom: 26px;
        left: 50%;
        width: clamp(22px, 3vw, 36px);
        height: clamp(22px, 3vw, 36px);
        border-top: solid 1px var(--bgb-color_secondary--light);
        border-right: solid 1px var(--bgb-color_secondary--light);
        transform: translateX(-50%) rotate(135deg);
        @media screen and (max-width: 768px) {
          bottom: 24px;
        }
      }
      &::after {
        content: '';
        position: absolute;
        bottom: 20px;
        left: 50%;
        display: block;
        width: 1px;
        height: 45px;
        background-color: var(--bgb-color_secondary--light);
        transform: translateX(-50%);
        @media screen and (max-width: 768px) {
          height: 35px;
        }
      }
    }
    .block__title {
      position: relative;
      margin-bottom: 20px;
      padding-left: 50px;
      &::before {
        display: inline-block;
        content: counter(about__flow_number);
        min-width: 45px;
        font-family: var(--bgb-font_en);
        font-size: clamp(4.0rem, 7vw, 5.0rem);
        font-weight: 200;
        font-style: italic;
        line-height: 1;
        @media screen and (max-width: 768px) {
          min-width: 20px;
        }
      }
      .-icon {
        position: absolute;
        top: 45%;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 38px;
        height: 38px;
        background-color: var( --bgb-color_tertiary);
        border-radius: 38px;
        font-size: 1.3rem;
        font-weight: 500;
        text-align: center;
        color: var(--bgb-color_white);
        transform: translateY(-50%);
      }
      .-title {
        padding-left: 15px;
        font-size: clamp(2.4rem, 3vw, 3.0rem);
        font-weight: 500;
        letter-spacing: 0.1em;
      }
    }
    .block__detail {
      padding-left: 120px;
      @media screen and (max-width: 768px) {
        padding-left: 90px;
      }
      .-link {
        position: relative;
        display: inline-block;
        padding-left: 20px;
        &::before {
          content: '';
          position: absolute;
          top: 5px;
          left: 0;
          width: 15px;
          height: 15px;
          background-color: var(--bgb-color_secondary--light);
          border-radius: 15px;
          z-index: 1;
        }
        &::after {
          content: '';
          position: absolute;
          top: 10px;
          left: 4px;
          width: 5px;
          height: 5px;
          border-top: solid 1px var(--bgb-color_white);
          border-right: solid 1px var(--bgb-color_white);
          transform: rotate(45deg);
          z-index: 2;
        }
      }
    }
  }
}

/* 会社概要 */
/* ---------------------------------------- */
[data-bgb] .p-company__catch--image {
  max-width: 900px;
  margin-right: auto;
  margin-left: auto;
}
[data-bgb] .p-company__catch--copy {
  font-size: clamp(2.0rem, 3vw, 2.4rem);
  font-weight: 600;
  letter-spacing: 0.1em;
  text-align: center;
}
