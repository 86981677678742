@charset "UTF-8";
/* --------------------------------------------------
// bge_style - BurgerEditor
-------------------------------------------------- */
:root {
  --bgb-color_primary: #ec6c00;
  --bgb-color_secondary: #032739;
  --bgb-color_secondary--light: #626b70;
  --bgb-color_tertiary: #c7a956;
  --bgb-color_white: #fff;
  --bgb-color_gray: #f7f7f8;
  --bgb-color_black: #032739;
  --bgb-color_link: #3e90b2;
  --bgb-color_table_border: #d0d2d4;
  --bgb-block_mt: 1.5em;
  --bgb-font_en: "Inter", sans-serif;
}

[data-bgt=download-file] .bgt-download-file__link::before, [data-bgt=button] .bgt-btn::before {
  content: "";
  position: absolute;
  top: 0;
  right: 200%;
  width: 100%;
  height: 100%;
  transition: right 0.3s 0s ease;
  z-index: 1;
}

.c-page_main > [data-bgb] {
  margin-top: var(--bgb-block_mt);
}
.c-page_main [data-bgb]:first-of-type {
  margin-top: 0;
}

[data-bgb] h2 {
  position: relative;
  margin-top: var(--bgb-block_mt);
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  border-bottom: solid 1px var(--bgb-color_secondary);
  font-size: clamp(2.5rem, 3vw, 3.6rem);
  font-weight: 600;
  line-height: 1.4;
  color: var(--bgb-color_secondary);
}
[data-bgb] h2::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 5px;
  height: 10px;
  background-color: var(--bgb-color_secondary);
}
[data-bgb] h3 {
  position: relative;
  margin-top: calc(var(--bgb-block_mt) * 1.5);
  padding-left: 25px;
  font-size: clamp(2.2rem, 2.5vw, 3rem);
  font-weight: 600;
  line-height: 1.4;
  color: var(--bgb-color_secondary);
}
[data-bgb] h3::before {
  content: "";
  position: absolute;
  top: 0.7em;
  left: 0;
  width: 15px;
  height: 1px;
  background-color: var(--bgb-color_secondary);
  font-size: clamp(2.2rem, 2.5vw, 3rem);
}
.bgb-title + [data-bgb] h3 {
  margin-top: var(--bgb-block_mt);
}
[data-bgb] h4 {
  margin-top: calc(var(--bgb-block_mt) * 1.5);
  font-size: clamp(1.8rem, 2.2vw, 2.4rem);
  font-weight: 500;
  line-height: 1.4;
  color: var(--bgb-color_secondary);
}
[data-bgb] h5 {
  margin-top: var(--bgb-block_mt);
  font-size: clamp(1.6rem, 2.2vw, 1.8rem);
  font-weight: 500;
  line-height: 1.4;
  color: var(--bgb-color_secondary);
}
[data-bgb] h6 {
  margin-top: var(--bgb-block_mt);
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.4;
  color: var(--bgb-color_secondary);
}
[data-bgb] p {
  font-size: clamp(1.4rem, 1.5vw, 1.5rem);
  line-height: 1.8;
  letter-spacing: 0.08em;
  color: var(--bgb-color_secondary--light);
}
[data-bgb] p:not(:first-child) {
  margin-top: var(--bgb-block_mt);
}
[data-bgb] ul {
  margin-top: var(--bgb-block_mt);
  padding-left: 20px;
  color: var(--bgb-color_secondary--light);
}
[data-bgb] ul :where(li ul) {
  margin-top: 0;
}
[data-bgb] ul li {
  font-size: clamp(1.4rem, 1.5vw, 1.5rem);
}
[data-bgb] ul > li {
  position: relative;
  padding-left: 20px;
}
[data-bgb] ul > li::before {
  content: "";
  position: absolute;
  top: 0.9em;
  left: 0;
  width: 5px;
  height: 1px;
  background-color: var(--bgb-color_secondary--light);
  font-size: clamp(1.4rem, 1.5vw, 1.5rem);
}
[data-bgb] ul > li :where(ul, ol) {
  margin-top: 0;
  padding-left: 0;
}
[data-bgb] ul > li :where(ul, ol) > li {
  position: relative;
  padding-left: 18px;
}
[data-bgb] ul > li :where(ul, ol) > li::before {
  content: "";
  position: absolute;
  top: 11px;
  left: 0;
  width: 6px;
  height: 6px;
  border-radius: 10px;
  background-color: var(--bgb-color_secondary--light);
}
[data-bgb] ol {
  counter-reset: list_number 0;
  margin-top: var(--bgb-block_mt);
  color: var(--bgb-color_secondary--light);
}
[data-bgb] ol :where(li ul) {
  margin-top: 0;
}
[data-bgb] ol li {
  font-size: clamp(1.4rem, 1.5vw, 1.5rem);
}
[data-bgb] ol > li {
  counter-increment: list_number 1;
  position: relative;
  padding-left: 20px;
}
[data-bgb] ol > li::before {
  content: counter(list_number, decimal-leading-zero) ".";
  font-family: var(--bgb-font_en);
  font-weight: 200;
  letter-spacing: 0.08em;
}
[data-bgb] ol > li :where(ul, ol) {
  margin-top: 0;
}
[data-bgb] strong {
  font-weight: bold;
}
[data-bgb] b {
  font-weight: bold;
}
[data-bgb] u {
  text-decoration: underline;
}
[data-bgb] a {
  font-weight: 600;
  text-decoration: underline;
}
[data-bgb] a:where(:hover, :focus) {
  color: var(--bgb-color_link);
}
[data-bgb] sup {
  font-size: 60%;
  vertical-align: super;
}
[data-bgb] sub {
  font-size: 60%;
  vertical-align: sub;
}
[data-bgb] s {
  text-decoration: line-through;
}
[data-bgb] blockquote {
  position: relative;
  margin-top: calc(var(--bgb-block_mt) * 2);
  margin-bottom: calc(var(--bgb-block_mt) * 2);
  padding: 20px 20px 20px 55px;
  background-color: var(--bgb-color_gray);
}
@media screen and (max-width: 768px) {
  [data-bgb] blockquote {
    padding: 15px 15px 15px 50px;
  }
}
[data-bgb] blockquote::before {
  content: "";
  position: absolute;
  top: 20px;
  left: 20px;
  display: block;
  width: 25px;
  height: 19px;
  background: url(../img/icon_blockquote.svg) no-repeat center center;
  background-size: 100%;
}
@media screen and (max-width: 768px) {
  [data-bgb] blockquote::before {
    width: 18px;
    height: 14px;
  }
}
[data-bgb] blockquote > *:first-child {
  margin-top: 0;
}
[data-bgb] table {
  width: 100%;
  color: var(--bgb-color_black);
}
[data-bgb] table tr {
  border-bottom: solid 1px var(--bgb-color_table_border);
}
[data-bgb] table tr:first-child {
  border-top: solid 1px var(--bgb-color_table_border);
}
[data-bgb] table th {
  padding: 10px;
  background-color: var(--bgb-color_gray);
}
[data-bgb] table td {
  padding: 10px;
}
@media screen and (max-width: 768px) {
  [data-bgb] .bgt-grid {
    margin-bottom: 20px;
  }
}

[data-bgt=button] {
  display: block !important;
  text-align: center;
}
[data-bgt=button] .bgt-btn {
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: clamp(280px, 50%, 500px);
  min-height: 60px;
  margin-right: auto;
  margin-left: auto;
  padding: 15px 20px;
  background-color: var(--bgb-color_white);
  border: solid 3px var(--bgb-color_secondary--light);
  font-size: clamp(1.3rem, 1.5vw, 1.5rem);
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: 0.1em;
  text-decoration: none;
  transition: color 0.3s 0.1s ease;
  color: var(--bgb-color_secondary--light);
}
[data-bgt=button] .bgt-btn::before {
  background-color: var(--bgb-color_secondary--light);
}
[data-bgt=button] .bgt-btn:where(:hover, :focus) {
  color: var(--bgb-color_white);
}
[data-bgt=button] .bgt-btn:where(:hover, :focus)::before {
  right: 0;
}
[data-bgt=button] .bgt-btn__text {
  position: relative;
  z-index: 10;
}
[data-bgt=button] [data-bgt-button-kind=em] .bgt-btn {
  font-weight: 600;
}
[data-bgt=button] [data-bgt-button-kind=back] .bgt-btn {
  padding-left: 60px;
}
[data-bgt=button] [data-bgt-button-kind=back] .bgt-btn .bgt-btn__text {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  z-index: 15;
}
[data-bgt=button] [data-bgt-button-kind=back] .bgt-btn .bgt-btn__text::before {
  content: "";
  position: absolute;
  top: 50%;
  left: -40px;
  width: 25px;
  height: 1px;
  background-color: var(--bgb-color_secondary--light);
  transform: translateY(-50%);
}
[data-bgt=button] [data-bgt-button-kind=back] .bgt-btn .bgt-btn__text::after {
  content: "";
  position: absolute;
  top: 50%;
  left: -38px;
  width: 19px;
  height: 19px;
  border-bottom: solid 1px var(--bgb-color_secondary--light);
  border-left: solid 1px var(--bgb-color_secondary--light);
  transform: translateY(-50%) rotate(45deg);
}
[data-bgt=button] [data-bgt-button-kind=back] .bgt-btn:hover .bgt-btn__text::before, [data-bgt=button] [data-bgt-button-kind=back] .bgt-btn:focus .bgt-btn__text::before {
  background-color: var(--bgb-color_white);
}
[data-bgt=button] [data-bgt-button-kind=back] .bgt-btn:hover .bgt-btn__text::after, [data-bgt=button] [data-bgt-button-kind=back] .bgt-btn:focus .bgt-btn__text::after {
  border-color: var(--bgb-color_white);
}

[data-bgt=download-file] {
  display: block !important;
  text-align: center;
}
[data-bgt=download-file] .bgt-download-file__link {
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: clamp(280px, 50%, 500px);
  min-height: 60px;
  margin-right: auto;
  margin-left: auto;
  padding: 15px 20px;
  background-color: var(--bgb-color_white);
  border: solid 3px var(--bgb-color_secondary--light);
  font-size: clamp(1.3rem, 1.5vw, 1.5rem);
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: 0.1em;
  text-decoration: none;
  transition: color 0.3s 0.1s ease;
  color: var(--bgb-color_secondary--light);
}
[data-bgt=download-file] .bgt-download-file__link::before {
  background-color: var(--bgb-color_secondary--light);
}
[data-bgt=download-file] .bgt-download-file__link i,
[data-bgt=download-file] .bgt-download-file__link .bgt-link__name,
[data-bgt=download-file] .bgt-download-file__link .bgt-link__size {
  z-index: 5;
}
[data-bgt=download-file] .bgt-download-file__link .bgt-link__name {
  margin-right: 5px;
  margin-left: 5px;
}
[data-bgt=download-file] .bgt-download-file__link .bgt-link__size {
  font-size: 70%;
  letter-spacing: 0.05em;
}
[data-bgt=download-file] .bgt-download-file__link:where(:hover, :focus) {
  color: var(--bgb-color_white);
}
[data-bgt=download-file] .bgt-download-file__link:where(:hover, :focus)::before {
  right: 0;
}
[data-bgt=download-file] .bgt-download-file__link:where(:hover, :focus) i,
[data-bgt=download-file] .bgt-download-file__link:where(:hover, :focus) .bgt-link__name,
[data-bgt=download-file] .bgt-download-file__link:where(:hover, :focus) .bgt-link__size {
  color: var(--bgb-color_white);
}

.bgb-wysiwyg2 {
  padding: 20px;
}
@media screen and (max-width: 768px) {
  .bgb-wysiwyg2 .bgt-grid {
    padding-top: 0;
  }
}

.bgt-box__caption {
  margin-top: 10px;
  font-size: clamp(1.3rem, 1.5vw, 1.5rem);
}

[data-bgt=gallery] .bgt-gallery-ctrl__prev,
[data-bgt=gallery] .bgt-gallery-ctrl__next {
  display: block;
  width: 31px;
  height: 31px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 31px;
  font-size: 0;
  color: transparent;
}
[data-bgt=gallery] .bgt-gallery-ctrl__prev::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 13px;
  height: 13px;
  border-left: solid 1px var(--bgb-color_secondary--light);
  border-bottom: solid 1px var(--bgb-color_secondary--light);
  transform: translateY(-50%) translateX(-50%) rotate(45deg);
}
@media screen and (max-width: 768px) {
  [data-bgt=gallery] .bgt-gallery-ctrl__prev::before {
    bottom: 24px;
  }
}
[data-bgt=gallery] .bgt-gallery-ctrl__prev::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 15px;
  height: 1px;
  background-color: var(--bgb-color_secondary--light);
  transform: translateY(-50%) translateX(-50%);
}
[data-bgt=gallery] .bgt-gallery-ctrl__next::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 13px;
  height: 13px;
  border-top: solid 1px var(--bgb-color_secondary--light);
  border-right: solid 1px var(--bgb-color_secondary--light);
  transform: translateY(-50%) translateX(-50%) rotate(45deg);
}
@media screen and (max-width: 768px) {
  [data-bgt=gallery] .bgt-gallery-ctrl__next::before {
    bottom: 24px;
  }
}
[data-bgt=gallery] .bgt-gallery-ctrl__next::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 15px;
  height: 1px;
  background-color: var(--bgb-color_secondary--light);
  transform: translateY(-50%) translateX(-50%);
}
[data-bgt=gallery] [data-gallery-marker=thumbs] + .bgt-gallery-marker li {
  width: clamp(60px, 20%, 200px);
  height: clamp(60px, 100%, 100px);
  background-color: var(--bgb-color_secondary--light);
  aspect-ratio: 2/1;
}
[data-bgt=gallery] [data-gallery-marker=thumbs] + .bgt-gallery-marker li.current::before {
  content: "";
  top: 0;
  height: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: solid 3px var(--bgb-color_primary);
  pointer-events: none;
}
[data-bgt=gallery] [data-gallery-marker=dot] + .bgt-gallery-marker {
  margin-top: 20px;
}
[data-bgt=gallery] [data-gallery-marker=dot] + .bgt-gallery-marker li {
  width: 12px;
  height: 12px;
  margin-right: 5px;
  margin-left: 5px;
  background-image: none !important;
  background-color: var(--bgb-color_secondary--light);
  border-radius: 12px;
}
[data-bgt=gallery] [data-gallery-marker=dot] + .bgt-gallery-marker li.current {
  background-color: var(--bgb-color_primary);
}
[data-bgt=gallery] [data-gallery-marker=dot] + .bgt-gallery-marker li::before {
  content: "";
  display: none;
}

/* リノベコラム ビフォー・アフター */
/* ---------------------------------------- */
.bgb-image-text1 [data-bge=ckeditor],
.bgb-image-link-text1 [data-bge=ckeditor] {
  margin-top: 10px;
}

.bgb-image-text2 [data-bge=ckeditor],
.bgb-image-link-text2 [data-bge=ckeditor] {
  margin-top: 10px;
}

.bgb-image-text3 [data-bge=ckeditor],
.bgb-image-link-text3 [data-bge=ckeditor] {
  margin-top: 10px;
}

.bgb-image-text4 [data-bge=ckeditor],
.bgb-image-link-text4 [data-bge=ckeditor] {
  margin-top: 10px;
}

.bgb-image-text5 [data-bge=ckeditor],
.bgb-image-link-text5 [data-bge=ckeditor] {
  margin-top: 10px;
}

.bgb-image2.before_after,
.bgb-trimmed-image2.before_after,
.bgb-trimmed-image-link2.before_after,
.bgb-image-text2.before_after,
.bgb-image-link-text2.before_after {
  position: relative;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 35em) {
  .bgb-image2.before_after,
  .bgb-trimmed-image2.before_after,
  .bgb-trimmed-image-link2.before_after,
  .bgb-image-text2.before_after,
  .bgb-image-link-text2.before_after {
    display: block;
  }
}
.bgb-image2.before_after::before,
.bgb-trimmed-image2.before_after::before,
.bgb-trimmed-image-link2.before_after::before,
.bgb-image-text2.before_after::before,
.bgb-image-link-text2.before_after::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: clamp(22px, 3vw, 30px);
  height: clamp(22px, 3vw, 30px);
  border-top: solid 1px var(--bgb-color_secondary--light);
  border-right: solid 1px var(--bgb-color_secondary--light);
  transform: translateY(-50%) translateX(-50%) rotate(45deg);
}
@media screen and (max-width: 35em) {
  .bgb-image2.before_after::before,
  .bgb-trimmed-image2.before_after::before,
  .bgb-trimmed-image-link2.before_after::before,
  .bgb-image-text2.before_after::before,
  .bgb-image-link-text2.before_after::before {
    transform: translateY(0) translateX(-50%) rotate(135deg);
  }
}
.bgb-image2.before_after::after,
.bgb-trimmed-image2.before_after::after,
.bgb-trimmed-image-link2.before_after::after,
.bgb-image-text2.before_after::after,
.bgb-image-link-text2.before_after::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: clamp(19px, 4.5vw, 42px);
  height: 1px;
  background-color: var(--bgb-color_secondary--light);
  transform: translateY(-50%) translateX(-50%);
}
@media screen and (max-width: 35em) {
  .bgb-image2.before_after::after,
  .bgb-trimmed-image2.before_after::after,
  .bgb-trimmed-image-link2.before_after::after,
  .bgb-image-text2.before_after::after,
  .bgb-image-link-text2.before_after::after {
    width: 1px;
    height: 25px;
    transform: translateY(0) translateX(-50%);
  }
}
.bgb-image2.before_after > .bgt-grid,
.bgb-trimmed-image2.before_after > .bgt-grid,
.bgb-trimmed-image-link2.before_after > .bgt-grid,
.bgb-image-text2.before_after > .bgt-grid,
.bgb-image-link-text2.before_after > .bgt-grid {
  position: relative;
  float: none;
  width: clamp(150px, 45%, 460px);
}
@media screen and (max-width: 35em) {
  .bgb-image2.before_after > .bgt-grid,
  .bgb-trimmed-image2.before_after > .bgt-grid,
  .bgb-trimmed-image-link2.before_after > .bgt-grid,
  .bgb-image-text2.before_after > .bgt-grid,
  .bgb-image-link-text2.before_after > .bgt-grid {
    width: 100%;
  }
}
.bgb-image2.before_after > .bgt-grid::before,
.bgb-trimmed-image2.before_after > .bgt-grid::before,
.bgb-trimmed-image-link2.before_after > .bgt-grid::before,
.bgb-image-text2.before_after > .bgt-grid::before,
.bgb-image-link-text2.before_after > .bgt-grid::before {
  font-family: var(--bgb-font_en);
  font-size: clamp(1.3rem, 1.5vw, 1.5rem);
  font-weight: 400;
  letter-spacing: 0.08em;
  text-transform: capitalize;
  color: var(--bgb-color_table_border);
}
@media screen and (max-width: 35em) {
  .bgb-image2.before_after .bgt-grid--first,
  .bgb-trimmed-image2.before_after .bgt-grid--first,
  .bgb-trimmed-image-link2.before_after .bgt-grid--first,
  .bgb-image-text2.before_after .bgt-grid--first,
  .bgb-image-link-text2.before_after .bgt-grid--first {
    padding-bottom: 30px;
  }
}
.bgb-image2.before_after .bgt-grid--first::before,
.bgb-trimmed-image2.before_after .bgt-grid--first::before,
.bgb-trimmed-image-link2.before_after .bgt-grid--first::before,
.bgb-image-text2.before_after .bgt-grid--first::before,
.bgb-image-link-text2.before_after .bgt-grid--first::before {
  content: "Before";
}
@media screen and (max-width: 35em) {
  .bgb-image2.before_after .bgt-grid--last,
  .bgb-trimmed-image2.before_after .bgt-grid--last,
  .bgb-trimmed-image-link2.before_after .bgt-grid--last,
  .bgb-image-text2.before_after .bgt-grid--last,
  .bgb-image-link-text2.before_after .bgt-grid--last {
    padding-top: 30px;
  }
}
.bgb-image2.before_after .bgt-grid--last::before,
.bgb-trimmed-image2.before_after .bgt-grid--last::before,
.bgb-trimmed-image-link2.before_after .bgt-grid--last::before,
.bgb-image-text2.before_after .bgt-grid--last::before,
.bgb-image-link-text2.before_after .bgt-grid--last::before {
  content: "After";
}

/* はじめてのリノベーション */
/* ---------------------------------------- */
.p-about__renovation--list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-left: 0 !important;
}
.p-about__renovation .-list__item {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 48%;
  padding: 0 40px;
  margin-bottom: 60px;
  min-height: clamp(280px, 45vw, 480px);
}
@media screen and (max-width: 768px) {
  .p-about__renovation .-list__item {
    justify-content: center;
    width: clamp(280px, 90vw, 480px);
    min-height: clamp(280px, 90vw, 480px);
    margin-right: auto;
    margin-bottom: 30px;
    margin-left: auto;
  }
}
.p-about__renovation .-list__item:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: clamp(280px, 100%, 480px);
  min-height: 100%;
  background-color: var(--bgb-color_gray);
  border-radius: 1000px;
  transform: translateY(-50%) translateX(-50%);
  z-index: -1;
}
.p-about__renovation .-list__item--head {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 0;
  text-align: center;
}
.p-about__renovation .-list__item--head .-number {
  font-family: var(--bgb-font_en);
  font-size: clamp(4rem, 5vw, 6rem);
  font-weight: 200;
  font-style: italic;
}
.p-about__renovation .-list__item--head .-title {
  font-size: clamp(2rem, 3vw, 2.3rem);
  letter-spacing: 0.1em;
}
.p-about__renovation .-list__item--img {
  margin-top: 20px;
  margin-right: auto;
  margin-bottom: -40px;
  margin-left: auto;
}
@media screen and (max-width: 768px) {
  .p-about__renovation .-list__item--img {
    margin-bottom: 0;
  }
}
.p-about__renovation .-list__item--img01 {
  width: clamp(115px, 17vw, 230px);
}
.p-about__renovation .-list__item--img02 {
  width: clamp(145px, 21vw, 291px);
}
.p-about__renovation .-list__item--img03 {
  width: clamp(132px, 19vw, 264px);
}
@media screen and (min-width: 768px) {
  .p-about__renovation .-list__item--img03 {
    margin-top: 40px;
  }
}
.p-about__renovation .-list__item--img04 {
  width: clamp(181px, 25vw, 363px);
}
@media screen and (min-width: 768px) {
  .p-about__renovation .-list__item--img04 {
    margin-top: 30px;
  }
}

/* リノベーションのながれ */
/* ---------------------------------------- */
.p-about__flow {
  counter-reset: about__flow_number 0;
}
.p-about__flow--block {
  counter-increment: about__flow_number 1;
  position: relative;
}
.p-about__flow--block:not(:last-child) {
  padding-bottom: 100px;
}
.p-about__flow--block:not(:last-child)::before {
  content: "";
  position: absolute;
  bottom: 26px;
  left: 50%;
  width: clamp(22px, 3vw, 36px);
  height: clamp(22px, 3vw, 36px);
  border-top: solid 1px var(--bgb-color_secondary--light);
  border-right: solid 1px var(--bgb-color_secondary--light);
  transform: translateX(-50%) rotate(135deg);
}
@media screen and (max-width: 768px) {
  .p-about__flow--block:not(:last-child)::before {
    bottom: 24px;
  }
}
.p-about__flow--block:not(:last-child)::after {
  content: "";
  position: absolute;
  bottom: 20px;
  left: 50%;
  display: block;
  width: 1px;
  height: 45px;
  background-color: var(--bgb-color_secondary--light);
  transform: translateX(-50%);
}
@media screen and (max-width: 768px) {
  .p-about__flow--block:not(:last-child)::after {
    height: 35px;
  }
}
.p-about__flow--block .block__title {
  position: relative;
  margin-bottom: 20px;
  padding-left: 50px;
}
.p-about__flow--block .block__title::before {
  display: inline-block;
  content: counter(about__flow_number);
  min-width: 45px;
  font-family: var(--bgb-font_en);
  font-size: clamp(4rem, 7vw, 5rem);
  font-weight: 200;
  font-style: italic;
  line-height: 1;
}
@media screen and (max-width: 768px) {
  .p-about__flow--block .block__title::before {
    min-width: 20px;
  }
}
.p-about__flow--block .block__title .-icon {
  position: absolute;
  top: 45%;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  background-color: var(--bgb-color_tertiary);
  border-radius: 38px;
  font-size: 1.3rem;
  font-weight: 500;
  text-align: center;
  color: var(--bgb-color_white);
  transform: translateY(-50%);
}
.p-about__flow--block .block__title .-title {
  padding-left: 15px;
  font-size: clamp(2.4rem, 3vw, 3rem);
  font-weight: 500;
  letter-spacing: 0.1em;
}
.p-about__flow--block .block__detail {
  padding-left: 120px;
}
@media screen and (max-width: 768px) {
  .p-about__flow--block .block__detail {
    padding-left: 90px;
  }
}
.p-about__flow--block .block__detail .-link {
  position: relative;
  display: inline-block;
  padding-left: 20px;
}
.p-about__flow--block .block__detail .-link::before {
  content: "";
  position: absolute;
  top: 5px;
  left: 0;
  width: 15px;
  height: 15px;
  background-color: var(--bgb-color_secondary--light);
  border-radius: 15px;
  z-index: 1;
}
.p-about__flow--block .block__detail .-link::after {
  content: "";
  position: absolute;
  top: 10px;
  left: 4px;
  width: 5px;
  height: 5px;
  border-top: solid 1px var(--bgb-color_white);
  border-right: solid 1px var(--bgb-color_white);
  transform: rotate(45deg);
  z-index: 2;
}

/* 会社概要 */
/* ---------------------------------------- */
[data-bgb] .p-company__catch--image {
  max-width: 900px;
  margin-right: auto;
  margin-left: auto;
}

[data-bgb] .p-company__catch--copy {
  font-size: clamp(2rem, 3vw, 2.4rem);
  font-weight: 600;
  letter-spacing: 0.1em;
  text-align: center;
}